import { defineComponent, ref, computed } from 'vue'
import { useUXUIStore } from '@/store/uxui'

export default defineComponent({
  name: 'NavBar',
  setup() {
    const uxuiStore = useUXUIStore()
    const query = ref('')

    const onInputHandler = () => {
      console.log(query.value)
    }

    const isAsideCollapsed = computed(() => uxuiStore.asideCollapsed)

    const togglePanelHandler = (): void => {
      uxuiStore.switchAside()
    }

    return {
      query,
      onInputHandler,
      isAsideCollapsed,
      togglePanelHandler,
      uxuiStore,
    }
  },
})
