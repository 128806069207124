import { defineComponent, PropType, toRefs } from 'vue'
import {
  rightSwipeButtons,
  leftSwipeButtons,
  SwipeButton,
  SwipeButtons,
} from '@/config/buttonsMobileSwipeConfig'

export default defineComponent({
  name: 'ButtonsMobileSwipe',
  props: {
    item: {
      type: Object as PropType<{ id: number; [key: string]: any }>,
      required: true,
    },
    placement: {
      type: String as PropType<keyof SwipeButtons>,
      required: true,
    },
  },
  emits: {
    editLawsuit: null,
    deleteLawsuit: null,
    // типизация?
    openClient: null,
    deleteClient: null,
  },
  setup(props, { emit }) {
    const { item } = toRefs(props)

    const editLawsuitHandler = () => {
      emit('editLawsuit', item.value)
    }

    const deleteLawsuitHandler = () => {
      emit('deleteLawsuit', item.value.id)
    }

    const openClientHandler = () => {
      emit('openClient', item.value.id)
    }

    const deleteClientHandler = () => {
      emit('deleteClient', item.value.id)
    }

    const handlers: { [key: string]: any } = {
      lawsuit: {
        edit: editLawsuitHandler,
        remove: deleteLawsuitHandler,
      },
      client: {
        open: openClientHandler,
        remove: deleteClientHandler,
      },
      task: {
        // TODO: добавить хэндлеры тасков
        complete: () => {},
        bookmark: () => {},
      },
    }

    const writeHandlers = (buttonsArray: SwipeButton[]) => {
      return buttonsArray.map((btn) => ({
        ...btn,
        handler: handlers[props.placement][btn.action],
      }))
    }

    const rightSwipeBtnsOnPage = writeHandlers(
      rightSwipeButtons[props.placement],
    )
    const leftSwipeBtnsOnPage = writeHandlers(leftSwipeButtons[props.placement])

    return {
      rightSwipeBtnsOnPage,
      leftSwipeBtnsOnPage,
      editLawsuitHandler,
      deleteLawsuitHandler,
      openClientHandler,
      deleteClientHandler,
    }
  },
})
