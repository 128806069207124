import { MenuItemDropdown } from '@/types/createWidget'

export const menuItems: MenuItemDropdown[] = [
  {
    id: 1,
    title: 'Клиента',
    color: '',
    function: '',
  },
  {
    id: 2,
    title: 'Дело',
    color: '',
    function: 'createLawsuit',
  },
  {
    id: 3,
    title: 'Событие',
    color: '',
    function: '',
  },
  {
    id: 4,
    title: 'Задачу',
    color: '',
    function: '',
  },
  {
    id: 5,
    title: 'Заметку',
    color: '',
    function: '',
  },
  {
    id: 6,
    title: 'Транзакцию',
    color: '',
    function: '',
  },
]
