<template>
  <div class="main">
    <!--    header-->
    <main>
      <slot />
    </main>
    <!--    footer-->
  </div>
</template>

<script lang="ts" src="./AuthLayout.ts"></script>
