export type SwipeButton = {
  action: string
  icon: string
  class: string
  handler?: () => void
}

export type SwipeButtons = {
  lawsuit: SwipeButton[]
  client: SwipeButton[]
  task: SwipeButton[]
}

const CLASS_BASIS = 'swipe__btn_type_'

const commonButtons = {
  trash: {
    action: 'remove',
    icon: 'trash04',
    class: `${CLASS_BASIS}remove`,
  },
}

export const rightSwipeButtons: SwipeButtons = {
  lawsuit: [
    {
      action: 'edit',
      icon: 'edit2',
      class: `${CLASS_BASIS}edit`,
    },
  ],
  client: [
    {
      action: 'open',
      icon: 'user',
      class: `${CLASS_BASIS}user`,
    },
  ],
  task: [
    {
      action: 'complete',
      icon: 'done',
      class: `${CLASS_BASIS}done`,
    },
    {
      action: 'bookmark',
      icon: 'bookmark',
      class: `${CLASS_BASIS}bookmark`,
    },
  ],
}

export const leftSwipeButtons: SwipeButtons = {
  lawsuit: [commonButtons.trash],
  client: [commonButtons.trash],
  task: [
    commonButtons.trash,
    // TODO: кнопки добавления и извлечения задачи в/из акт/-а
  ],
}
